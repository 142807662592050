import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Swal from "sweetalert2";
import Grid from "@mui/material/Grid";

import { Card, CircularProgress, Divider } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { ResponseAlert } from "components/ResponseAlert";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { verifyCurrency } from "utils/validations";

import { WithdrawConfirm } from "api/withdraw";
import { CheckBalance } from "api/member";

function WithdrawForm() {
  const [balance, setBalance] = useState();
  const [amount, setAmount] = useState();
  const [error, setError] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const newSwal = Swal.mixin({
    customClass: {
      confirmButton: "button button-info",
      cancelButton: "button button-error",
    },
  });

  async function checkBalance() {
    setLoading(true);
    try {
      const response = await CheckBalance();
      console.log("MemberInfo-response", response);
      setLoading(false);
      const status = response?.data?.status;
      if (status === "success") {
        console.log("MemberInfo-response", response);
        setBalance(response.data?.data?.balance);
      } else {
        ResponseAlert(response);
      }
    } catch (err) {
      console.log("MemberInfo-catch-err", err.response);
      setLoading(false);
      ResponseAlert(err.response);
    }
  }

  useEffect(() => {
    checkBalance();
  }, []);

  const handleSetAmount = (event) => {
    setError(undefined);
    const v = event.target.value;
    if (!verifyCurrency(v)) {
      setError("กรอกเฉพาะตัวเลข, จำนวนเต็ม, จำนวนทศนิยม 2 หลัก เท่านั้น เช่น 100, 100.50");
    }
    setAmount(v);
  };

  async function handelConfirmWithdraw(payload) {
    try {
      const response = await WithdrawConfirm(payload);
      const data = response?.data?.data;
      const status = response?.data?.status;
      console.log("handelConfirmDeposit-response", response);
      if (status === "success") {
        Swal.fire({
          title: "บันทึกรายการถอนสำเร็จ",
          icon: "success",
          html: `กรุณารอทำรายการสักครู่นะคะ`,
          preConfirm: () => {
            history.push(`/dashboard`);
          },
        });
      } else if (status === 1 && data?.result?.eligible === false) {
        Swal.close();
        const min = response?.data?.data?.result?.wait;
        const wait = 15 - Number(min);
        console.log("handleTranferPopup-wait", wait);
        alert(`สามารถทำรายการถอนได้อีกครั้งในอีก ${wait} นาที`);
      } else {
        ResponseAlert(response);
      }
    } catch (err) {
      ResponseAlert(err.response);
    }
  }

  async function handleWithdraw(event) {
    if (event) {
      event.preventDefault();
    }
    if (amount && verifyCurrency(amount) && parseFloat(balance) >= parseFloat(amount)) {
      const newAmount = formatter.format(amount).toString();
      newSwal.fire({
        title: "ยืนยันทำรายการถอน",
        icon: "info",
        html: `จำนวน: <b>${newAmount}</b>`,
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        reverseButtons: true,
        preConfirm: () => {
          Swal.fire({
            title: "กำลังทำรายการ",
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          const nAmount = parseFloat(amount);
          const payload = {
            amount: nAmount.toFixed(2),
          };
          handelConfirmWithdraw(payload);
        },
      });
    } else {
      Swal.fire("", "กรุณาตรวจสอบยอดเงินที่ต้องการถอน", "error");
    }
  }

  return (
    <SuiBox px={2}>
      <Grid container justifyContent="center" mb={3}>
        <Grid item xs={12} lg={8}>
          <Card sx={{ p: 2 }}>
            <SuiBox mt={2} mb={1} textAlign="center">
              <SuiTypography variant="h5" fontWeight="medium">
                ถอนเงิน
              </SuiTypography>
            </SuiBox>
            <Divider />
            <SuiBox p={2}>
              <SuiBox textAlign="center">
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textColor="secondary"
                >
                  ยอดที่สามารถถอนได้
                </SuiTypography>
                <SuiTypography variant="h1" fontWeight="bold" textAlign="center">
                  {isLoading ? (
                    <CircularProgress size="2rem" />
                  ) : (
                    formatter.format(balance).toString()
                  )}
                </SuiTypography>
              </SuiBox>

              <SuiBox mt={3}>
                <SuiTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textColor="dark"
                >
                  จำนวนเงินที่ต้องการถอน
                </SuiTypography>
                <SuiInput
                  value={amount}
                  onChange={(event) => handleSetAmount(event)}
                  placeholder="จำนวน"
                  type="number"
                />
                <SuiBox>
                  <SuiTypography component="label" variant="caption" textColor="error">
                    {error}
                  </SuiTypography>
                </SuiBox>
              </SuiBox>

              <SuiBox mt={3} width="100%" display="flex" justifyContent="flex-end">
                <SuiButton
                  type="submit"
                  variant="gradient"
                  buttonColor="dark"
                  onClick={(event) => handleWithdraw(event)}
                >
                  ยืนยัน
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </Card>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default WithdrawForm;
